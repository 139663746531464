/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
    this.headers = [];
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.status === 204) return response;

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          }
          return response;
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   * Set Headers
   * @method
   * @param headers - array of headers
   */
  setHeaders(headers) {
    this.headers = headers;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
   * Delete push token by UUID. Only push token, created by authorized app can be deleted.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} tokenId -
   */
  deleteUserPushToken(tokenId, parameters = {}) {
    let path = '/me/push-tokens/{tokenId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{tokenId}', tokenId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update or Create Push token. Push token is assigned to sportnet user, app_id and appSpace (from authorization).
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  manageUserPushToken(parameters = {}) {
    let path = '/me/push-tokens';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  createInvitation(parameters = {}) {
    let path = '/invitations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  getInvitation(invitationId, parameters = {}) {
    let path = '/invitations/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} parameters.appId -
   * @param {boolean} parameters.pending -
   */
  getAppspaceInvitations(appSpace, parameters = {}) {
    let path = '/ppoinvitations/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['appId'] !== 'undefined') {
      queryParameters['appId'] = parameters['appId'];
    }

    queryParameters['appId'] = this.transformParameter(
      queryParameters['appId'],
    );

    queryParameters['pending'] = false;

    if (typeof parameters['pending'] !== 'undefined') {
      queryParameters['pending'] = parameters['pending'];
    }

    queryParameters['pending'] = this.transformParameter(
      queryParameters['pending'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  getInvitationPublic(invitationId, parameters = {}) {
    let path = '/invitations/{invitationId}/user';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} invitationId - Invitation Id
   */
  resolveInvitation(invitationId, parameters = {}) {
    let path = '/invitations/{invitationId}/user';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Registracia noveho usera z externého systému na pozadí.

Pouzívateľ sa zaregistruje pokiaľ neexistuje, ak existuje, tak registrácia neprebehne,
len sa doplní informácia o registrácii z externej aplikácie.

**Duplicita sa vyhodnocuje podľa `idnr` teda rodného čísla**, ktoré je povinné pri tomto type registrácie.

Ak používateľ neexistuje, vytvorí sa s prihlasovacím menom vo forme `meno.priezvisko`.

Ak idnr nie je zadane, tak sa pouzivatel neregistruje.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.data - 
     */
  externalRegistration(parameters = {}) {
    let path = '/external/registration';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.ids -
   * @param {array} parameters.sectors -
   * @param {array} parameters.type -
   * @param {string} parameters.q - Regex search in _id and name
   */
  organizationPPOProfiles(parameters = {}) {
    let path = '/ppo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['sectors'] !== 'undefined') {
      queryParameters['sectors'] = parameters['sectors'];
    }

    queryParameters['sectors'] = this.transformParameter(
      queryParameters['sectors'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOProfile(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Zoznam transferov konkretnej PPO z pohladu zvazu. Zatial su podporovane len futbalsfz.sk a len sektor sport:futbal:futbal
     * @method
     * @param {object} parameters - method options and parameters
       * @param {integer} parameters.limit - Limit for paginated results
       * @param {integer} parameters.offset - Offset for paginated results
       * @param {string} parameters.transferType - 
       * @param {string} parameters.gender - 
       * @param {string} parameters.age - 
       * @param {string} parameters.dateFrom - transfer.date_from >= dateFrom
       * @param {string} parameters.dateTo - transfer.date_from <= dateTo
       * @param {string} parameters.ppo - Based on the ppoDir parameter:

- in: transfer.org_profile_id = ppo
- out: transfer.source_ppo = ppo
- both: transfer.org_profile_id = ppo $OR transfer.source_ppo = ppo

       * @param {string} parameters.ppoDir - 
      
      
     */
  listTransfers(appSpace, sectorId, parameters = {}) {
    let path = '/ppo/{appSpace}/transfers/{sectorId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['transferType'] !== 'undefined') {
      queryParameters['transferType'] = parameters['transferType'];
    }

    queryParameters['transferType'] = this.transformParameter(
      queryParameters['transferType'],
    );

    if (typeof parameters['gender'] !== 'undefined') {
      queryParameters['gender'] = parameters['gender'];
    }

    queryParameters['gender'] = this.transformParameter(
      queryParameters['gender'],
    );

    if (typeof parameters['age'] !== 'undefined') {
      queryParameters['age'] = parameters['age'];
    }

    queryParameters['age'] = this.transformParameter(queryParameters['age']);

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['dateFrom'] = parameters['dateFrom'];
    }

    queryParameters['dateFrom'] = this.transformParameter(
      queryParameters['dateFrom'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['dateTo'] = parameters['dateTo'];
    }

    queryParameters['dateTo'] = this.transformParameter(
      queryParameters['dateTo'],
    );

    if (typeof parameters['ppo'] !== 'undefined') {
      queryParameters['ppo'] = parameters['ppo'];
    }

    queryParameters['ppo'] = this.transformParameter(queryParameters['ppo']);

    queryParameters['ppoDir'] = 'both';

    if (typeof parameters['ppoDir'] !== 'undefined') {
      queryParameters['ppoDir'] = parameters['ppoDir'];
    }

    queryParameters['ppoDir'] = this.transformParameter(
      queryParameters['ppoDir'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{sectorId}', sectorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.q -
   * @param {array} parameters.ppoType -
   * @param {string} parameters.relationStatus -
   * @param {array} parameters.sports - Match only PPOs with one of speficied sports
   * @param {array} parameters.with - Add elements to projection
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOListRelatedPPOs(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/related-ppos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ppoType'] !== 'undefined') {
      queryParameters['ppoType'] = parameters['ppoType'];
    }

    queryParameters['ppoType'] = this.transformParameter(
      queryParameters['ppoType'],
    );

    if (typeof parameters['relationStatus'] !== 'undefined') {
      queryParameters['relationStatus'] = parameters['relationStatus'];
    }

    queryParameters['relationStatus'] = this.transformParameter(
      queryParameters['relationStatus'],
    );

    if (typeof parameters['sports'] !== 'undefined') {
      queryParameters['sports'] = parameters['sports'];
    }

    queryParameters['sports'] = this.transformParameter(
      queryParameters['sports'],
    );

    if (typeof parameters['with'] !== 'undefined') {
      queryParameters['with'] = parameters['with'];
    }

    queryParameters['with'] = this.transformParameter(queryParameters['with']);

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  organizationRelatedPPO(appSpace, ppo, parameters = {}) {
    let path = '/ppo/{appSpace}/related-ppos/{ppo}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.ppoType -
   * @param {string} appSpace - App space (PPO _id)
   */
  getPPORelationsStructure(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/relations-structure';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoType'] !== 'undefined') {
      queryParameters['ppoType'] = parameters['ppoType'];
    }

    queryParameters['ppoType'] = this.transformParameter(
      queryParameters['ppoType'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  getPPOLicensesIssuer(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/licenses-issuer';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOGDPRBody(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/gdpr';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOCookiesSettings(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/cookies';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOContactInfo(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/contact-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOContactPersons(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/contact-persons';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  organizationPPOTheme(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/theme';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.relatedTo -
   * @param {string} parameters.q -
   * @param {string} parameters.autocomplete - Use autocomplete search and return highlights
   * @param {string} parameters.affiliationDateFrom -
   * @param {string} parameters.affiliationDateTo -
   * @param {string} parameters.birthdateFrom -
   * @param {string} parameters.birthdateTo -
   * @param {string} parameters.affiliationCategory - Either sport_orgs or sport_expert_orgs
   * @param {string} parameters.competenceType - You need to define affiliationCategory for this filter to work
   * @param {boolean} parameters.affiliationActive -
   * @param {boolean} parameters.membershipActive -
   * @param {boolean} parameters.bioActive -
   * @param {string} parameters.sportSector -
   * @param {array} parameters.ids -
   * @param {string} parameters.regnr -
   * @param {string} parameters.sorter - Define sort order
   */
  organizationPPOUsers(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['relatedTo'] !== 'undefined') {
      queryParameters['relatedTo'] = parameters['relatedTo'];
    }

    queryParameters['relatedTo'] = this.transformParameter(
      queryParameters['relatedTo'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['autocomplete'] !== 'undefined') {
      queryParameters['autocomplete'] = parameters['autocomplete'];
    }

    queryParameters['autocomplete'] = this.transformParameter(
      queryParameters['autocomplete'],
    );

    if (typeof parameters['affiliationDateFrom'] !== 'undefined') {
      queryParameters['affiliationDateFrom'] =
        parameters['affiliationDateFrom'];
    }

    queryParameters['affiliationDateFrom'] = this.transformParameter(
      queryParameters['affiliationDateFrom'],
    );

    if (typeof parameters['affiliationDateTo'] !== 'undefined') {
      queryParameters['affiliationDateTo'] = parameters['affiliationDateTo'];
    }

    queryParameters['affiliationDateTo'] = this.transformParameter(
      queryParameters['affiliationDateTo'],
    );

    if (typeof parameters['birthdateFrom'] !== 'undefined') {
      queryParameters['birthdateFrom'] = parameters['birthdateFrom'];
    }

    queryParameters['birthdateFrom'] = this.transformParameter(
      queryParameters['birthdateFrom'],
    );

    if (typeof parameters['birthdateTo'] !== 'undefined') {
      queryParameters['birthdateTo'] = parameters['birthdateTo'];
    }

    queryParameters['birthdateTo'] = this.transformParameter(
      queryParameters['birthdateTo'],
    );

    if (typeof parameters['affiliationCategory'] !== 'undefined') {
      queryParameters['affiliationCategory'] =
        parameters['affiliationCategory'];
    }

    queryParameters['affiliationCategory'] = this.transformParameter(
      queryParameters['affiliationCategory'],
    );

    if (typeof parameters['competenceType'] !== 'undefined') {
      queryParameters['competenceType'] = parameters['competenceType'];
    }

    queryParameters['competenceType'] = this.transformParameter(
      queryParameters['competenceType'],
    );

    queryParameters['affiliationActive'] = true;

    if (typeof parameters['affiliationActive'] !== 'undefined') {
      queryParameters['affiliationActive'] = parameters['affiliationActive'];
    }

    queryParameters['affiliationActive'] = this.transformParameter(
      queryParameters['affiliationActive'],
    );

    queryParameters['membershipActive'] = false;

    if (typeof parameters['membershipActive'] !== 'undefined') {
      queryParameters['membershipActive'] = parameters['membershipActive'];
    }

    queryParameters['membershipActive'] = this.transformParameter(
      queryParameters['membershipActive'],
    );

    queryParameters['bioActive'] = false;

    if (typeof parameters['bioActive'] !== 'undefined') {
      queryParameters['bioActive'] = parameters['bioActive'];
    }

    queryParameters['bioActive'] = this.transformParameter(
      queryParameters['bioActive'],
    );

    if (typeof parameters['sportSector'] !== 'undefined') {
      queryParameters['sportSector'] = parameters['sportSector'];
    }

    queryParameters['sportSector'] = this.transformParameter(
      queryParameters['sportSector'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['regnr'] !== 'undefined') {
      queryParameters['regnr'] = parameters['regnr'];
    }

    queryParameters['regnr'] = this.transformParameter(
      queryParameters['regnr'],
    );

    queryParameters['sorter'] = 'person';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {boolean} parameters.withRelatedAffiliations -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  organizationPPOUser(appSpace, userId, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['withRelatedAffiliations'] = false;

    if (typeof parameters['withRelatedAffiliations'] !== 'undefined') {
      queryParameters['withRelatedAffiliations'] =
        parameters['withRelatedAffiliations'];
    }

    queryParameters['withRelatedAffiliations'] = this.transformParameter(
      queryParameters['withRelatedAffiliations'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.ppoTypes -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  organizationPPOUserSportOrgs(appSpace, userId, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}/sport-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoTypes'] !== 'undefined') {
      queryParameters['ppoTypes'] = parameters['ppoTypes'];
    }

    queryParameters['ppoTypes'] = this.transformParameter(
      queryParameters['ppoTypes'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.ppoTypes -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  organizationPPOUserSportExpertOrgs(appSpace, userId, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}/sport-expert-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoTypes'] !== 'undefined') {
      queryParameters['ppoTypes'] = parameters['ppoTypes'];
    }

    queryParameters['ppoTypes'] = this.transformParameter(
      queryParameters['ppoTypes'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  organizationPPOUserLicenses(appSpace, userId, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Can be used as image src
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   * @param {string} token - Formal photo token
   */
  getFormalImage(appSpace, userId, token, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}/formal-photo/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/jpeg');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  organizationPPOUserTransfers(appSpace, userId, parameters = {}) {
    let path = '/ppo/{appSpace}/users/{userId}/transfers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  ppoOrganizationBankAccounts(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/organization/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   * @param {string} appSpace - App space (PPO _id)
   */
  ppoOrganizationAddresses(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/organization/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  organizationInvoiceProfile(orgId, parameters = {}) {
    let path = '/organizations/{orgId}/invoice-profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  ppoInvoiceProfile(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/invoice-profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * You can mix ppo / user ids and get user profiles in one request
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  organizationMixedPPOUsers(parameters = {}) {
    let path = '/registry/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.dateFrom -
   * @param {string} parameters.dateTo -
   * @param {array} parameters.ids -
   */
  DSGOrganizationsList(parameters = {}) {
    let path = '/registry/dajmespolugol/organizations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['dateFrom'] !== 'undefined') {
      queryParameters['date_from'] = parameters['dateFrom'];
    }

    queryParameters['date_from'] = this.transformParameter(
      queryParameters['date_from'],
    );

    if (typeof parameters['dateTo'] !== 'undefined') {
      queryParameters['date_to'] = parameters['dateTo'];
    }

    queryParameters['date_to'] = this.transformParameter(
      queryParameters['date_to'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} profileId -
   */
  DSGOrganizationDetail(profileId, parameters = {}) {
    let path = '/registry/dajmespolugol/organizations/{profileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{profileId}', profileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.externalProfile - Search user by externalProfile
   * @param {string} parameters.email - Fulltext search query
   * @param {array} parameters.ids - Search only user IDs
   * @param {string} parameters.orgProfileId - Search only users defined as sportmens from this org_profile_id. In conjunction with `dajmespolugol=true`
   * @param {string} parameters.sorter - Define sort order
   */
  DSGUsersList(parameters = {}) {
    let path = '/registry/dajmespolugol/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['externalProfile'] !== 'undefined') {
      queryParameters['externalProfile'] = parameters['externalProfile'];
    }

    queryParameters['externalProfile'] = this.transformParameter(
      queryParameters['externalProfile'],
    );

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    if (typeof parameters['orgProfileId'] !== 'undefined') {
      queryParameters['org_profile_id'] = parameters['orgProfileId'];
    }

    queryParameters['org_profile_id'] = this.transformParameter(
      queryParameters['org_profile_id'],
    );

    queryParameters['sorter'] = '-created_datetime';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  sendCourierMessages(parameters = {}) {
    let path = '/courier';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Endpoint for sending push notifications.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  sendPushNotification(parameters = {}) {
    let path = '/push-notification';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Vytvorenie faktury pre PPO z externej sluzby
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} ppo - PPO _id
   */
  createPPOInvoice(ppo, parameters = {}) {
    let path = '/invoice/{ppo}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Storno faktury pre PPO z externej sluzby
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} ppo - PPO _id
   * @param {string} invoicenr - invoicenr
   */
  stornoPPOInvoice(ppo, invoicenr, parameters = {}) {
    let path = '/invoice/{ppo}/{invoicenr}/storno';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{ppo}', ppo);

    path = path.replace('{invoicenr}', invoicenr);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Vytvorenie polozky faktury pre PPO z externej sluzby
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} ppo - PPO _id
   * @param {string} buyerPpo - PPO _id
   */
  createPPOInvoiceItems(ppo, buyerPpo, parameters = {}) {
    let path = '/invoice-items/{ppo}/ppo/{buyerPPO}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{ppo}', ppo);

    path = path.replace('{buyerPPO}', buyerPpo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Ziskanie PDF faktury pre PPO z externej sluzby
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} ppo - PPO _id
   * @param {string} invoicenr - invoicenr
   */
  getPPOInvoiceByNr(ppo, invoicenr, parameters = {}) {
    let path = '/invoice/{ppo}/{invoicenr}/pdf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{ppo}', ppo);

    path = path.replace('{invoicenr}', invoicenr);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetCourierTemplates(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/courier/templates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmSendMessages(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/courier/templates';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} courierTemplateId - Courier Template Id
   */
  crmGetCourierTemplateById(appSpace, courierTemplateId, parameters = {}) {
    let path = '/crm/{appSpace}/courier/templates/{courierTemplateId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{courierTemplateId}', courierTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} courierTemplateId - Courier Template Id
   */
  crmGetCourierMessagesByTemplateId(
    appSpace,
    courierTemplateId,
    parameters = {},
  ) {
    let path = '/crm/{appSpace}/courier/templates/{courierTemplateId}/messages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{courierTemplateId}', courierTemplateId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  getPublicAppSpaceGroups(appSpace, parameters = {}) {
    let path = '/ppo/{appSpace}/user-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetAppSpaceGroups(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmCreateAppSpaceGroup(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q -
   * @param {array} parameters.userGroupIds -
   * @param {string} parameters.sorter -
   */
  getUsersByAppSpaceGroup(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['userGroupIds'] !== 'undefined') {
      queryParameters['userGroupIds'] = parameters['userGroupIds'];
    }

    queryParameters['userGroupIds'] = this.transformParameter(
      queryParameters['userGroupIds'],
    );

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userGroupId - User Group Id
   */
  crmGetAppSpaceGroup(appSpace, userGroupId, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups/detail/{userGroupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userGroupId}', userGroupId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userGroupId - User Group Id
   */
  crmUpdateAppSpaceGroup(appSpace, userGroupId, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups/detail/{userGroupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userGroupId}', userGroupId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  createInvitationRequestToGroups(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user-groups/invitation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUpdateProfileLogo(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/logo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie loga PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmDeleteProfileLogo(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/logo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUpdateProfileFavicon(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/favicon';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie favicon-u PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmDeleteProfileFavicon(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/favicon';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie suhlasu s GDPR
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetGDPRBody(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/gdpr';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre update suhlasu s GDPR
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUpdateGDPRBody(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/gdpr';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie suhlasu s GDPR
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetCookiesSettings(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/cookies';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre update suhlasu s GDPR
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUpdateCookiesSettings(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/cookies';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update PPO contact info
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUpdateContactInfo(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/contact-info';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update PPO theme
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUpdateTheme(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/settings/theme';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie sekvencii appspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} sequenceId -
   */
  crmGetSequence(appSpace, sequenceId, parameters = {}) {
    let path = '/crm/{appSpace}/sequences/{sequenceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{sequenceId}', sequenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre upravu sekvencie
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - data to update
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} sequenceId -
   */
  crmUpdateSequence(appSpace, sequenceId, parameters = {}) {
    let path = '/crm/{appSpace}/sequences/{sequenceId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{sequenceId}', sequenceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie zoznamu faktur podla appspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.buyer -
   * @param {string} parameters.q -
   * @param {string} parameters.deliveryDateFrom -
   * @param {string} parameters.deliveryDateTo -
   * @param {string} appSpace - App space (PPO _id)
   */
  getInvoicesByAppspace(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/invoices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['buyer'] !== 'undefined') {
      queryParameters['buyer'] = parameters['buyer'];
    }

    queryParameters['buyer'] = this.transformParameter(
      queryParameters['buyer'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['deliveryDateFrom'] !== 'undefined') {
      queryParameters['deliveryDateFrom'] = parameters['deliveryDateFrom'];
    }

    queryParameters['deliveryDateFrom'] = this.transformParameter(
      queryParameters['deliveryDateFrom'],
    );

    if (typeof parameters['deliveryDateTo'] !== 'undefined') {
      queryParameters['deliveryDateTo'] = parameters['deliveryDateTo'];
    }

    queryParameters['deliveryDateTo'] = this.transformParameter(
      queryParameters['deliveryDateTo'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie zoznamu otvorenych faktur
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.buyer -
   * @param {string} parameters.q -
   * @param {string} appSpace - App space (PPO _id)
   */
  getOpenedInvoiceItemsByAppspace(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/invoice-items';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['buyer'] !== 'undefined') {
      queryParameters['buyer'] = parameters['buyer'];
    }

    queryParameters['buyer'] = this.transformParameter(
      queryParameters['buyer'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre uzavretie poloziek zbernej FA - vytvorenie finalnych FA
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  commitOpenedInvoiceItems(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/invoice-items';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie download ID pdf faktury
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} invoiceNr -
   */
  getInvoicePdfByNr(appSpace, invoiceNr, parameters = {}) {
    let path = '/crm/{appSpace}/invoices/{invoiceNr}/pdf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{invoiceNr}', invoiceNr);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre stornovanie faktury
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} invoiceNr -
   */
  stornoInvoice(appSpace, invoiceNr, parameters = {}) {
    let path = '/crm/{appSpace}/invoices/{invoiceNr}/storno';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{invoiceNr}', invoiceNr);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie download ID pdf faktury
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {array} parameters.invoiceIds -
   */
  getInvoicesPohodaXmlByIds(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/invoices-export/pohoda';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['invoiceIds'] !== 'undefined') {
      queryParameters['invoiceIds'] = parameters['invoiceIds'];
    }

    queryParameters['invoiceIds'] = this.transformParameter(
      queryParameters['invoiceIds'],
    );

    if (typeof parameters['invoiceIds'] === 'undefined') {
      throw Error('Missing required parameter: invoiceIds');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Public metoda na ziskanie cennika sluzieb spolocnosti
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.applicationId -
   * @param {string} parameters.type -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmPPOPricelist(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/pricelist';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['applicationId'] !== 'undefined') {
      queryParameters['applicationId'] = parameters['applicationId'];
    }

    queryParameters['applicationId'] = this.transformParameter(
      queryParameters['applicationId'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Create new pricelist item
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - data to update
   * @param {string} appSpace - App space (PPO _id)
   */
  crmPPOPricelistItemCreate(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/pricelist';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Public metoda na ziskanie detailu ceny
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmPPOPricelistItem(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/pricelist/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update pricelist item
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - data to update
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmPPOPricelistItemUpdate(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/pricelist/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete pricelist item
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmPPOPricelistItemDelete(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/pricelist/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} orgId - Organization Id
   */
  crmOrganizationFullProfile(appSpace, orgId, parameters = {}) {
    let path = '/crm/{appSpace}/organizations/{orgId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.ppoType -
   * @param {array} parameters.sports - Match only PPOs with one of speficied sports
   * @param {array} parameters.with - Add elements to projection
   * @param {string} parameters.output - Specify output format
   * @param {string} appSpace - App space (PPO _id)
   */
  crmListRelatedPPOs(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/related-ppos';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['ppoType'] !== 'undefined') {
      queryParameters['ppoType'] = parameters['ppoType'];
    }

    queryParameters['ppoType'] = this.transformParameter(
      queryParameters['ppoType'],
    );

    if (typeof parameters['sports'] !== 'undefined') {
      queryParameters['sports'] = parameters['sports'];
    }

    queryParameters['sports'] = this.transformParameter(
      queryParameters['sports'],
    );

    if (typeof parameters['with'] !== 'undefined') {
      queryParameters['with'] = parameters['with'];
    }

    queryParameters['with'] = this.transformParameter(queryParameters['with']);

    queryParameters['output'] = 'json';

    if (typeof parameters['output'] !== 'undefined') {
      queryParameters['output'] = parameters['output'];
    }

    queryParameters['output'] = this.transformParameter(
      queryParameters['output'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Prislusnosti k inym PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - I[date] PPO relation
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} relationId - Id polozky (_id v ramci subdokumentu)
   */
  crmUpdateRelation(appSpace, relationId, parameters = {}) {
    let path = '/crm/{appSpace}/related-ppos/{relationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{relationId}', relationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  crmPPOMembership(appSpace, ppo, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/membership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create PPO membership
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  crmPPOMembershipCreate(appSpace, ppo, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/membership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} ppo - PPO _id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmPPOMembershipGet(ppo, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{ppo}', ppo);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update PPO membership
   * @param {string} ppo - PPO _id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmPPOMembershipUpdate(ppo, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{ppo}', ppo);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} ppo - PPO _id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmPPOMembershipDelete(ppo, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{ppo}', ppo);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  crmPPOSectors(appSpace, ppo, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/sectors';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update PPO sectors. Only sectors with matricityPPO=appSpace can be deleted and only sectors included withing sectors of appSpace can be added. Whole list mus be updated at once.
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  crmPPOSectorsUpdate(appSpace, ppo, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/sectors';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  crmPPOShortnames(appSpace, ppo, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/shortnames';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create PPO shortname
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  crmPPOShortnameCreate(appSpace, ppo, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/shortnames';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update PPO shortname
   * @param {string} ppo - PPO _id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmPPOShortnameUpdate(ppo, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/shortnames/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{ppo}', ppo);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} ppo - PPO _id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmPPOShortnameDelete(ppo, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/shortnames/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{ppo}', ppo);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} ppo - PPO _id
   */
  crmPPOContactPersons(appSpace, ppo, parameters = {}) {
    let path = '/crm/{appSpace}/ppos/{ppo}/contact-persons';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{ppo}', ppo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmApps(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/apps';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.callbackUrl -
   * @param {boolean} parameters.redirect - If true, server generates `Location` header
   * @param {boolean} parameters.test - Run in testing environment
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmAppPayment(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/app/{itemId}/payment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['callbackUrl'] !== 'undefined') {
      queryParameters['callbackUrl'] = parameters['callbackUrl'];
    }

    queryParameters['callbackUrl'] = this.transformParameter(
      queryParameters['callbackUrl'],
    );

    if (typeof parameters['callbackUrl'] === 'undefined') {
      throw Error('Missing required parameter: callbackUrl');
    }

    queryParameters['redirect'] = false;

    if (typeof parameters['redirect'] !== 'undefined') {
      queryParameters['redirect'] = parameters['redirect'];
    }

    queryParameters['redirect'] = this.transformParameter(
      queryParameters['redirect'],
    );

    queryParameters['test'] = false;

    if (typeof parameters['test'] !== 'undefined') {
      queryParameters['test'] = parameters['test'];
    }

    queryParameters['test'] = this.transformParameter(queryParameters['test']);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {boolean} parameters.test - Run in testing environment
   * @param {string} appSpace - App space (PPO _id)
   * @param {number} orderId -
   */
  crmAppPaymentResult(appSpace, orderId, parameters = {}) {
    let path = '/crm/{appSpace}/app/{orderId}/payment/result';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['test'] = false;

    if (typeof parameters['test'] !== 'undefined') {
      queryParameters['test'] = parameters['test'];
    }

    queryParameters['test'] = this.transformParameter(queryParameters['test']);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{orderId}', orderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - data to update
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationRequestChange(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/request-change';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganization(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - data to update
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationUpdate(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationMembership(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/membership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationContactPersons(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/contact-persons';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationContactPersonCreate(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/contact-persons';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationContactPerson(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/contact-persons/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationContactPersonUpdate(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/contact-persons/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationContactPersonDelete(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/contact-persons/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} membershipToPpo - PPO _id to which should be membership prolonged
   */
  crmMyOrganizationMembershipProlongInfo(
    appSpace,
    membershipToPpo,
    parameters = {},
  ) {
    let path =
      '/crm/{appSpace}/myorganization/membership-prolong/{membershipToPPO}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{membershipToPPO}', membershipToPpo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} membershipToPpo - PPO _id to which should be membership prolonged
   */
  crmMyOrganizationMembershipProlong(
    appSpace,
    membershipToPpo,
    parameters = {},
  ) {
    let path =
      '/crm/{appSpace}/myorganization/membership-prolong/{membershipToPPO}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{membershipToPPO}', membershipToPpo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationLogoUpload(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/logo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie loga organizacie
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationLogoDelete(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/logo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationFaviconUpload(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/favicon';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie favicon-u organizacie
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationFaviconDelete(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/favicon';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationAddresses(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationAddressesCreate(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationAddressesGet(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationAddressesUpdate(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationBankAccounts(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Bank Account
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationBankAccountsCreate(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationBankAccountsGet(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Bank Account
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationBankAccountsUpdate(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationCustomFields(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Custom Field
   * @param {string} appSpace - App space (PPO _id)
   */
  crmMyOrganizationCustomFieldsCreate(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationCustomFieldsGet(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  crmMyOrganizationCustomFieldsUpdate(appSpace, itemId, parameters = {}) {
    let path = '/crm/{appSpace}/myorganization/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetUserAcl(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/acl';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetAuthUser(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - Fulltext search query
   * @param {string} parameters.autocomplete - Automplete search query
   * @param {string} parameters.email - Fulltext search query
   * @param {array} parameters.ids - Search only user IDs
   * @param {string} parameters.sorter - Define sort order
   * @param {array} parameters.athletType - hodnoty z ciselnika sport-org-competence-type
   * @param {boolean} parameters.affiliationActive -
   * @param {array} parameters.sportExpertType - hodnoty z ciselnika sport-expert-competence-type
   * @param {array} parameters.licenseType - hodnoty z ciselnika license-type
   * @param {string} parameters.birthdateFrom - User birthdate from
   * @param {string} parameters.birthdateTo - User birthdate to
   * @param {string} parameters.sex - User's gender
   * @param {boolean} parameters.isAthlete -
   * @param {boolean} parameters.isSportExpert -
   * @param {string} parameters.relatedTo -
   * @param {string} parameters.sportSector -
   * @param {string} parameters.membershipValidTo -
   * @param {string} parameters.membershipToPpo -
   * @param {boolean} parameters.membershipValidToIsInvalid -
   * @param {array} parameters.with - Add elements to projection
   * @param {string} parameters.output - Specify output format
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetUsers(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['autocomplete'] !== 'undefined') {
      queryParameters['autocomplete'] = parameters['autocomplete'];
    }

    queryParameters['autocomplete'] = this.transformParameter(
      queryParameters['autocomplete'],
    );

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    queryParameters['sorter'] = '-created_datetime';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['athletType'] !== 'undefined') {
      queryParameters['athlet_type'] = parameters['athletType'];
    }

    queryParameters['athlet_type'] = this.transformParameter(
      queryParameters['athlet_type'],
    );

    if (typeof parameters['affiliationActive'] !== 'undefined') {
      queryParameters['affiliation_active'] = parameters['affiliationActive'];
    }

    queryParameters['affiliation_active'] = this.transformParameter(
      queryParameters['affiliation_active'],
    );

    if (typeof parameters['sportExpertType'] !== 'undefined') {
      queryParameters['sport_expert_type'] = parameters['sportExpertType'];
    }

    queryParameters['sport_expert_type'] = this.transformParameter(
      queryParameters['sport_expert_type'],
    );

    if (typeof parameters['licenseType'] !== 'undefined') {
      queryParameters['license_type'] = parameters['licenseType'];
    }

    queryParameters['license_type'] = this.transformParameter(
      queryParameters['license_type'],
    );

    if (typeof parameters['birthdateFrom'] !== 'undefined') {
      queryParameters['birthdate_from'] = parameters['birthdateFrom'];
    }

    queryParameters['birthdate_from'] = this.transformParameter(
      queryParameters['birthdate_from'],
    );

    if (typeof parameters['birthdateTo'] !== 'undefined') {
      queryParameters['birthdate_to'] = parameters['birthdateTo'];
    }

    queryParameters['birthdate_to'] = this.transformParameter(
      queryParameters['birthdate_to'],
    );

    if (typeof parameters['sex'] !== 'undefined') {
      queryParameters['sex'] = parameters['sex'];
    }

    queryParameters['sex'] = this.transformParameter(queryParameters['sex']);

    if (typeof parameters['isAthlete'] !== 'undefined') {
      queryParameters['isAthlete'] = parameters['isAthlete'];
    }

    queryParameters['isAthlete'] = this.transformParameter(
      queryParameters['isAthlete'],
    );

    if (typeof parameters['isSportExpert'] !== 'undefined') {
      queryParameters['isSportExpert'] = parameters['isSportExpert'];
    }

    queryParameters['isSportExpert'] = this.transformParameter(
      queryParameters['isSportExpert'],
    );

    if (typeof parameters['relatedTo'] !== 'undefined') {
      queryParameters['relatedTo'] = parameters['relatedTo'];
    }

    queryParameters['relatedTo'] = this.transformParameter(
      queryParameters['relatedTo'],
    );

    if (typeof parameters['sportSector'] !== 'undefined') {
      queryParameters['sportSector'] = parameters['sportSector'];
    }

    queryParameters['sportSector'] = this.transformParameter(
      queryParameters['sportSector'],
    );

    if (typeof parameters['membershipValidTo'] !== 'undefined') {
      queryParameters['membershipValidTo'] = parameters['membershipValidTo'];
    }

    queryParameters['membershipValidTo'] = this.transformParameter(
      queryParameters['membershipValidTo'],
    );

    if (typeof parameters['membershipToPpo'] !== 'undefined') {
      queryParameters['membershipToPPO'] = parameters['membershipToPpo'];
    }

    queryParameters['membershipToPPO'] = this.transformParameter(
      queryParameters['membershipToPPO'],
    );

    if (typeof parameters['membershipValidToIsInvalid'] !== 'undefined') {
      queryParameters['membershipValidToIsInvalid'] =
        parameters['membershipValidToIsInvalid'];
    }

    queryParameters['membershipValidToIsInvalid'] = this.transformParameter(
      queryParameters['membershipValidToIsInvalid'],
    );

    if (typeof parameters['with'] !== 'undefined') {
      queryParameters['with'] = parameters['with'];
    }

    queryParameters['with'] = this.transformParameter(queryParameters['with']);

    queryParameters['output'] = 'json';

    if (typeof parameters['output'] !== 'undefined') {
      queryParameters['output'] = parameters['output'];
    }

    queryParameters['output'] = this.transformParameter(
      queryParameters['output'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create new basic user
   * @param {string} appSpace - App space (PPO _id)
   */
  crmCreateUser(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetUser(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  getUsersGroups(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/user-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmSetUserGroups(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/user-groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserTransfers(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/transfers';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetUserProfile(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update basic user profile data
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUpdateUserProfile(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {boolean} parameters.public - Is photo public?
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserPhotoUpload(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    if (typeof parameters['public'] !== 'undefined') {
      form['public'] = parameters['public'];
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserPhotoDelete(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmEndUserRelation(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/endrelations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAddresses(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAddressesCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAddressesGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAddressesUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAddressesDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBankAccounts(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Bank Account
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBankAccountsCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBankAccountsGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Bank Account
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBankAccountsUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBankAccountsDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserCustomFields(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Custom Field
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserCustomFieldsCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserCustomFieldsGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserCustomFieldsUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserCustomFieldsDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBio(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bio';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Bio Item
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBioCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bio';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBioGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bio/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Bio
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBioUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bio/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserBioDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/bio/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserSportOrgs(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - New entry
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserRegisterSportOrg(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserGetSportOrg(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update data
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserSportOrgUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserSportOrgDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserSportExpertOrgs(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - New entry
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserRegisterSportExpertOrg(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserGetSportExpertOrg(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update data
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserSportExpertOrgUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserSportExpertOrgDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/sport-expert-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAwards(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/awards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create User Award
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAwardsCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/awards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAwardsGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/awards/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update User Award
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserAwardsUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/awards/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} membershipToPpo - PPO _id to which should be membership prolonged
   */
  crmProlongUsersMembership(appSpace, membershipToPpo, parameters = {}) {
    let path = '/crm/{appSpace}/users-membership-prolong/{membershipToPPO}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{membershipToPPO}', membershipToPpo);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  crmProlongPPOsMembership(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/ppos-membership-prolong';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMembership(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/membership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create User membership
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMembershipCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/membership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMembershipGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update User membership
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMembershipUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMembershipDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserRegnrs(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/regnrs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create User regnr
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserRegnrCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/regnrs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserRegnrGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/regnrs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update User regnr
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserRegnrUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/regnrs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserRegnrDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/regnrs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserDocuments(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/documents';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {string} parameters.type - Type of the file
   * @param {string} parameters.orgProfileId - Type of the file
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserDocumentCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/documents';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    if (typeof parameters['type'] !== 'undefined') {
      form['type'] = parameters['type'];
    }

    if (typeof parameters['type'] === 'undefined') {
      throw Error('Missing required parameter: type');
    }

    if (typeof parameters['orgProfileId'] !== 'undefined') {
      form['org_profile_id'] = parameters['orgProfileId'];
    }

    if (typeof parameters['orgProfileId'] === 'undefined') {
      throw Error('Missing required parameter: orgProfileId');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserDocumentGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/documents/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie dokumentu
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserDocumentDelete(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/documents/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserDocumentDownloader(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/documents/{itemId}/downloader';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserLicenses(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create User Award
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserLicenseCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserLicenseGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update User Award
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserLicenseUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserLicenseFileUpload(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/licenses/{itemId}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Use url to download file
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} fileId -
   */
  crmUserLicenseFileGet(userId, itemId, appSpace, fileId, parameters = {}) {
    let path =
      '/crm/{appSpace}/users/{userId}/licenses/{itemId}/files/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie suboru licencie
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} fileId -
   */
  crmUserLicenseFileDelete(userId, itemId, appSpace, fileId, parameters = {}) {
    let path =
      '/crm/{appSpace}/users/{userId}/licenses/{itemId}/files/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMedicalExaminations(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/medical-examinations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create User Medical examination
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMedicalExaminationCreate(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/medical-examinations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMedicalExaminationGet(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/medical-examinations/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Medical examination
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserMedicalExaminationUpdate(userId, itemId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/medical-examinations/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserFormalPhotos(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/formal-photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {boolean} parameters.public - Is photo public?
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserFormalPhotoUpload(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/formal-photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    if (typeof parameters['public'] !== 'undefined') {
      form['public'] = parameters['public'];
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie formalnej fotky
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} appSpace - App space (PPO _id)
   */
  crmUserFormalPhotoDelete(userId, appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/users/{userId}/formal-photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  createDSGEditRequest(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/create-request/type/dsg-edit';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} transferType -
   */
  createPersonTransferRequest(appSpace, transferType, parameters = {}) {
    let path = '/crm/{appSpace}/create-request/person-transfer-{transferType}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{transferType}', transferType);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - Fulltext search query
   * @param {array} parameters.statuses - Statuses of request, one or more of `[NEW, CANCELLED, APPROVED, REJECTED, CONFIRMED, PAYMENT_REQUIRED, CLOSED]`
   * @param {array} parameters.requestTypes - Types of request
   * @param {string} appSpace - App space (PPO _id)
   */
  crmGetRequests(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/requests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['statuses'] !== 'undefined') {
      queryParameters['statuses'] = parameters['statuses'];
    }

    queryParameters['statuses'] = this.transformParameter(
      queryParameters['statuses'],
    );

    if (typeof parameters['requestTypes'] !== 'undefined') {
      queryParameters['requestTypes'] = parameters['requestTypes'];
    }

    queryParameters['requestTypes'] = this.transformParameter(
      queryParameters['requestTypes'],
    );

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmRequestDetail(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmGetRelatedRequests(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/related';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   * @param {number} idx - Attachment index
   */
  crmGetRequestAttachmentFile(appSpace, requestId, idx, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/attachment/{idx}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    path = path.replace('{idx}', idx);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Status either `[CANCELLED, NEW, REJECTED]`
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmChangeRequestStatus(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/changeStatus';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmTransferRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/confirm/transfer';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmPoDataChangeRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/confirm/po-data-change';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmUserProfileChangeRequest(appSpace, requestId, parameters = {}) {
    let path =
      '/crm/{appSpace}/requests/{requestId}/confirm/user-profile-change';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmSportOrgRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/confirm/sport-org';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmSportExpertOrgRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/confirm/sport-expert-org';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmDSGEditRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/confirm/dsg-edit';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmPoRegistryRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/confirm/po-registry';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmConfirmLicenseRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/confirm/license';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} requestId - Request Id
   */
  crmClosePoRegistryRequest(appSpace, requestId, parameters = {}) {
    let path = '/crm/{appSpace}/requests/{requestId}/close/po-registry';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} requestId - Request Id
   */
  requestDetail(requestId, parameters = {}) {
    let path = '/requests/{requestId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   * @param {string} parameters.fileId - Filename that will be saved to disk
   * @param {string} parameters.name - Name/Description of the file
   * @param {string} requestId - Request Id
   */
  uploadRequestAttachment(requestId, parameters = {}) {
    let path = '/requests/{requestId}/attachment';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['fileId'] !== 'undefined') {
      form['fileId'] = parameters['fileId'];
    }

    if (typeof parameters['fileId'] === 'undefined') {
      throw Error('Missing required parameter: fileId');
    }

    if (typeof parameters['name'] !== 'undefined') {
      form['name'] = parameters['name'];
    }

    if (typeof parameters['name'] === 'undefined') {
      throw Error('Missing required parameter: name');
    }

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} requestId - Request Id
   * @param {number} idx - Attachment index
   */
  getRequestAttachmentFile(requestId, idx, parameters = {}) {
    let path = '/requests/{requestId}/attachment/{idx}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{requestId}', requestId);

    path = path.replace('{idx}', idx);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} requestId - Request Id
   */
  cancelRequest(requestId, parameters = {}) {
    let path = '/requests/{requestId}/cancel';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} requestId - Request Id
   */
  approveRequest(requestId, parameters = {}) {
    let path = '/requests/{requestId}/approve';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{requestId}', requestId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.q -
   * @param {string} parameters.businessId - Search organizations by ICO
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   */
  getOrganizations(parameters = {}) {
    let path = '/organizations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['businessId'] !== 'undefined') {
      queryParameters['businessId'] = parameters['businessId'];
    }

    queryParameters['businessId'] = this.transformParameter(
      queryParameters['businessId'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  getOrganizationProfiles(orgId, parameters = {}) {
    let path = '/organizations/{orgId}/profiles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - Fulltext search query
   * @param {array} parameters.ids - Get by ids
   * @param {string} parameters.sorter - Define sort order
   */
  adminGetOrganizations(parameters = {}) {
    let path = '/admin/organizations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    queryParameters['sorter'] = '-name';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - data to update
   */
  adminCreateOrganization(parameters = {}) {
    let path = '/admin/organizations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  adminGetOrganization(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - data to update
   * @param {string} orgId - Organization Id
   */
  adminUpdateOrganization(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   * @param {string} orgId - Organization Id
   */
  adminOrganizationAddresses(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} orgId - Organization Id
   */
  adminOrganizationAddressesCreate(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationAddressesGet(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationAddressesUpdate(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationAddressesDelete(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  adminOrganizationBankAccounts(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Bank Account
   * @param {string} orgId - Organization Id
   */
  adminOrganizationBankAccountsCreate(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationBankAccountsGet(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Bank Account
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationBankAccountsUpdate(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationBankAccountsDelete(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  adminOrganizationCustomFields(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Custom Field
   * @param {string} orgId - Organization Id
   */
  adminOrganizationCustomFieldsCreate(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationCustomFieldsGet(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationCustomFieldsUpdate(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationCustomFieldsDelete(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   */
  adminOrganizationProfiles(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profiles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create New organization profile
   * @param {string} orgId - Organization Id
   */
  adminOrganizationProfileCreate(orgId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profiles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationProfile(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profiles/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update organization profile
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationProfileUpdate(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profiles/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Zoznam aplikacii povolenych pre PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationProfileApps(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profiles/{itemId}/apps';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Prislusnosti k inym PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationProfileRelations(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profiles/{itemId}/relations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Prislusnosti k inym PPO - vytvorenie novej
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create PPO relation
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationProfileCreateRelation(orgId, itemId, parameters = {}) {
    let path = '/admin/organizations/{orgId}/profiles/{itemId}/relations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Prislusnosti k inym PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} orgId - Organization Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} relationId - Id polozky (_id v ramci subdokumentu)
   */
  adminOrganizationProfileRelation(orgId, itemId, relationId, parameters = {}) {
    let path =
      '/admin/organizations/{orgId}/profiles/{itemId}/relations/{relationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{orgId}', orgId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{relationId}', relationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  adminCreatePPOSector(appSpace, parameters = {}) {
    let path = '/admin/ppo/{appSpace}/sectors';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} sectorId - ID of sector to be deleted
   */
  adminDeletePPOSector(appSpace, sectorId, parameters = {}) {
    let path = '/admin/ppo/{appSpace}/sectors/{sectorId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{sectorId}', sectorId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Zoznam aplikacii povolenych pre PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  adminPpoApps(appSpace, parameters = {}) {
    let path = '/admin/ppo/{appSpace}/apps';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Vytvorenie novej PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  adminCreateOrganizationPPOProfile(parameters = {}) {
    let path = '/admin/ppo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get business info based on ICO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {boolean} parameters.forceFinstat - Force finstat results
   * @param {boolean} parameters.sportnetOnly - Check only sportnet DB
   * @param {boolean} parameters.withPpoIds - Check only sportnet DB
   * @param {string} ico - ICO na vyhladanie
   */
  getBusinessInfo(ico, parameters = {}) {
    let path = '/business-info/{ico}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['forceFinstat'] = false;

    if (typeof parameters['forceFinstat'] !== 'undefined') {
      queryParameters['forceFinstat'] = parameters['forceFinstat'];
    }

    queryParameters['forceFinstat'] = this.transformParameter(
      queryParameters['forceFinstat'],
    );

    queryParameters['sportnetOnly'] = false;

    if (typeof parameters['sportnetOnly'] !== 'undefined') {
      queryParameters['sportnetOnly'] = parameters['sportnetOnly'];
    }

    queryParameters['sportnetOnly'] = this.transformParameter(
      queryParameters['sportnetOnly'],
    );

    queryParameters['withPPOIds'] = false;

    if (typeof parameters['withPpoIds'] !== 'undefined') {
      queryParameters['withPPOIds'] = parameters['withPpoIds'];
    }

    queryParameters['withPPOIds'] = this.transformParameter(
      queryParameters['withPPOIds'],
    );

    path = path.replace('{ico}', ico);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get codelist ids
   * @method
   * @param {object} parameters - method options and parameters
   */
  listCodelists(parameters = {}) {
    let path = '/codelist';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get codelist
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} codelist - codelist id
   */
  getCodelist(codelist, parameters = {}) {
    let path = '/codelist/{codelist}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{codelist}', codelist);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get codelist
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} codelist - codelist id
   * @param {string} codelistItem - codelist item value
   */
  getCodelistSector(codelist, codelistItem, parameters = {}) {
    let path = '/codelist/{codelist}/{codelistItem}/sector';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{codelist}', codelist);

    path = path.replace('{codelistItem}', codelistItem);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get codelist
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} codelist - codelist id
   * @param {string} codelistItem - codelist item value
   * @param {string} sectorItem - sector value
   */
  getCodelistSectorDiscipline(
    codelist,
    codelistItem,
    sectorItem,
    parameters = {},
  ) {
    let path =
      '/codelist/{codelist}/{codelistItem}/sector/{sectorItem}/discipline';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{codelist}', codelist);

    path = path.replace('{codelistItem}', codelistItem);

    path = path.replace('{sectorItem}', sectorItem);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processAppgrantInvitationResponse(invitationId, parameters = {}) {
    let path = '/appgrant-invitation-callback/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processContactPersonInvitationResponse(invitationId, parameters = {}) {
    let path = '/crm-contact-person-invitation-callback/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processPersonRelationInvitationResponse(invitationId, parameters = {}) {
    let path = '/person-relation-invitation-callback/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Invite email for `appId` in `appSpace`. Use this method for update user permissions via invitation also.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  appgrantInviteUser(appId, appSpace, parameters = {}) {
    let path = '/appgrant-invitation/{appId}/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get grant roles for `appId` in `appSpace`
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  appgrantGetRoles(appId, appSpace, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Set grant roles for `appId` in `appSpace`. Use this method for update user permissions also.
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  appgrantRegisterUser(appId, appSpace, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete grant role for `appId` in `appSpace` for `userId`
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  appgrantDeleteRoleForUser(appId, appSpace, userId, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Helper method to get user public profile by userId
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  appgrantGetProfileForUser(appId, appSpace, userId, parameters = {}) {
    let path = '/appgrant/{appId}/{appSpace}/{userId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get public (primary) apps list
   * @method
   * @param {object} parameters - method options and parameters
   * @param {boolean} parameters.graph - Doplni objekty podaplikacii
   */
  getPublicApps(parameters = {}) {
    let path = '/apps';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['graph'] = false;

    if (typeof parameters['graph'] !== 'undefined') {
      queryParameters['graph'] = parameters['graph'];
    }

    queryParameters['graph'] = this.transformParameter(
      queryParameters['graph'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get public app detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   */
  getPublicApp(appId, parameters = {}) {
    let path = '/apps/{appId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of registered apps
   * @method
   * @param {object} parameters - method options and parameters
   */
  getApps(parameters = {}) {
    let path = '/admin/apps';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Register new app
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - App data
   */
  createApp(parameters = {}) {
    let path = '/admin/apps';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get app detail
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   */
  getApp(appId, parameters = {}) {
    let path = '/admin/apps/{appId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update app details (app secret, registered roles, subapp ids)
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - App data
   * @param {string} appId - App ID
   */
  updateApp(appId, parameters = {}) {
    let path = '/admin/apps/{appId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get appspaces registered for app
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   */
  getAppAppSpaces(appId, parameters = {}) {
    let path = '/admin/apps/{appId}/appspaces';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Register new appspace (allow organization profile id to use the app)
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appId - App ID
   */
  registerAppSpace(appId, parameters = {}) {
    let path = '/admin/apps/{appId}/appspaces';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get appspaces detail - list of users
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  getAppSpaceUsers(appId, appSpace, parameters = {}) {
    let path = '/admin/apps/{appId}/appspaces/{appSpace}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Register new user to appspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  registerAppSpaceUser(appId, appSpace, parameters = {}) {
    let path = '/admin/apps/{appId}/appspaces/{appSpace}/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete all user's rights in apps in some appSpace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.appIds -
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  deleteUserAppSpaces(appSpace, userId, parameters = {}) {
    let path = '/admin/apps/appspaces/{appSpace}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['appIds'] !== 'undefined') {
      queryParameters['appIds'] = parameters['appIds'];
    }

    queryParameters['appIds'] = this.transformParameter(
      queryParameters['appIds'],
    );

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Update appspace user
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  updateAppSpaceUser(appId, appSpace, userId, parameters = {}) {
    let path = '/admin/apps/{appId}/appspaces/{appSpace}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Delete appspace user
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} userId - User Id
   */
  deleteAppSpaceUser(appId, appSpace, userId, parameters = {}) {
    let path = '/admin/apps/{appId}/appspaces/{appSpace}/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {string} parameters.q - Fulltext search query
   * @param {string} parameters.email - Fulltext search query
   * @param {string} parameters.importId - Fulltext search query
   * @param {string} parameters.externalId - Fulltext search query
   * @param {array} parameters.ids - Search only user IDs
   * @param {string} parameters.sorter - Define sort order
   */
  adminGetUsers(parameters = {}) {
    let path = '/admin/users';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['importId'] !== 'undefined') {
      queryParameters['import_id'] = parameters['importId'];
    }

    queryParameters['import_id'] = this.transformParameter(
      queryParameters['import_id'],
    );

    if (typeof parameters['externalId'] !== 'undefined') {
      queryParameters['external_id'] = parameters['externalId'];
    }

    queryParameters['external_id'] = this.transformParameter(
      queryParameters['external_id'],
    );

    if (typeof parameters['ids'] !== 'undefined') {
      queryParameters['ids'] = parameters['ids'];
    }

    queryParameters['ids'] = this.transformParameter(queryParameters['ids']);

    queryParameters['sorter'] = '-created_datetime';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminGetUser(userId, parameters = {}) {
    let path = '/admin/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminDeleteUser(userId, parameters = {}) {
    let path = '/admin/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * List of all user roles, in all appspaces, divided by app_id

     * @method
     * @param {object} parameters - method options and parameters
       * @param {boolean} parameters.expandApp - Doplni objekty aplikacii
       * @param {string} userId - User Id
     */
  adminGetUserAppSpaces(userId, parameters = {}) {
    let path = '/admin/users/{userId}/appspaces';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['expandApp'] = false;

    if (typeof parameters['expandApp'] !== 'undefined') {
      queryParameters['expandApp'] = parameters['expandApp'];
    }

    queryParameters['expandApp'] = this.transformParameter(
      queryParameters['expandApp'],
    );

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get list of user's relations
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminGetUserRelations(userId, parameters = {}) {
    let path = '/admin/users/{userId}/relations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Add new relation to user
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} userId - User Id
   */
  adminAddUserRelation(userId, parameters = {}) {
    let path = '/admin/users/{userId}/relations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} relatedId - Relation ID to be deleted
   * @param {boolean} parameters.deleteOpponentRelation - Option to delete also opponent relation with this user
   */
  adminDeleteUserRelation(userId, relatedId, parameters = {}) {
    let path = '/admin/users/{userId}/relations/{relatedId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{relatedId}', relatedId);

    if (typeof parameters['deleteOpponentRelation'] !== 'undefined') {
      queryParameters['deleteOpponentRelation'] =
        parameters['deleteOpponentRelation'];
    }

    queryParameters['deleteOpponentRelation'] = this.transformParameter(
      queryParameters['deleteOpponentRelation'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminGetUserProfile(userId, parameters = {}) {
    let path = '/admin/users/{userId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update basic user profile data
   * @param {string} userId - User Id
   */
  adminUpdateUserProfile(userId, parameters = {}) {
    let path = '/admin/users/{userId}/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   * @param {string} userId - User Id
   */
  adminUserAddresses(userId, parameters = {}) {
    let path = '/admin/users/{userId}/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} userId - User Id
   */
  adminUserAddressesCreate(userId, parameters = {}) {
    let path = '/admin/users/{userId}/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserAddressesGet(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserAddressesUpdate(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserAddressesDelete(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserBankAccounts(userId, parameters = {}) {
    let path = '/admin/users/{userId}/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Bank Account
   * @param {string} userId - User Id
   */
  adminUserBankAccountsCreate(userId, parameters = {}) {
    let path = '/admin/users/{userId}/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserBankAccountsGet(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Bank Account
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserBankAccountsUpdate(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserBankAccountsDelete(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserPpoRelations(userId, parameters = {}) {
    let path = '/admin/users/{userId}/ppo-relations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserCustomFields(userId, parameters = {}) {
    let path = '/admin/users/{userId}/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Custom Field
   * @param {string} userId - User Id
   */
  adminUserCustomFieldsCreate(userId, parameters = {}) {
    let path = '/admin/users/{userId}/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserCustomFieldsGet(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserCustomFieldsUpdate(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserCustomFieldsDelete(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserSportOrgs(userId, parameters = {}) {
    let path = '/admin/users/{userId}/sport-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserGetSportOrg(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/sport-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserSportExpertOrgs(userId, parameters = {}) {
    let path = '/admin/users/{userId}/sport-expert-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserGetSportExpertOrg(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/sport-expert-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserAwards(userId, parameters = {}) {
    let path = '/admin/users/{userId}/awards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserAwardsGet(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/awards/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update User Award
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserAwardsUpdate(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/awards/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserExternalProfiles(userId, parameters = {}) {
    let path = '/admin/users/{userId}/external-profiles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} externalService - External service (e.g. issf, facebook, ...)
   */
  adminUserExternalProfile(userId, externalService, parameters = {}) {
    let path = '/admin/users/{userId}/external-profiles/{externalService}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{externalService}', externalService);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} externalService - External service (e.g. issf, facebook, ...)
   */
  adminUserExternalProfileDelete(userId, externalService, parameters = {}) {
    let path = '/admin/users/{userId}/external-profiles/{externalService}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{externalService}', externalService);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  adminUserLicenses(userId, parameters = {}) {
    let path = '/admin/users/{userId}/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  adminUserLicenseGet(userId, itemId, parameters = {}) {
    let path = '/admin/users/{userId}/licenses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.mainId -
   * @param {string} parameters.duplicatedId -
   */
  adminGetUserMergedProfile(parameters = {}) {
    let path = '/admin/users-merge';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['mainId'] !== 'undefined') {
      queryParameters['mainId'] = parameters['mainId'];
    }

    queryParameters['mainId'] = this.transformParameter(
      queryParameters['mainId'],
    );

    if (typeof parameters['mainId'] === 'undefined') {
      throw Error('Missing required parameter: mainId');
    }

    if (typeof parameters['duplicatedId'] !== 'undefined') {
      queryParameters['duplicatedId'] = parameters['duplicatedId'];
    }

    queryParameters['duplicatedId'] = this.transformParameter(
      queryParameters['duplicatedId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.mainId -
   * @param {string} parameters.duplicatedId -
   */
  adminMergeUsers(parameters = {}) {
    let path = '/admin/users-merge';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['mainId'] !== 'undefined') {
      queryParameters['mainId'] = parameters['mainId'];
    }

    queryParameters['mainId'] = this.transformParameter(
      queryParameters['mainId'],
    );

    if (typeof parameters['mainId'] === 'undefined') {
      throw Error('Missing required parameter: mainId');
    }

    if (typeof parameters['duplicatedId'] !== 'undefined') {
      queryParameters['duplicatedId'] = parameters['duplicatedId'];
    }

    queryParameters['duplicatedId'] = this.transformParameter(
      queryParameters['duplicatedId'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie download ID suboru licencie
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} fileId -
   */
  adminUserLicenseFileGet(userId, itemId, fileId, parameters = {}) {
    let path = '/admin/users/{userId}/licenses/{itemId}/files/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie suboru licencie
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   * @param {string} fileId -
   */
  adminUserLicenseFileDelete(userId, itemId, fileId, parameters = {}) {
    let path = '/admin/users/{userId}/licenses/{itemId}/files/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{itemId}', itemId);

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.externalProfile - Include external profile id for external service (e.g. 'sme')
   * @param {boolean} parameters.withGroups - Include groups for PPO, which the accessToken is registered
   */
  me(parameters = {}) {
    let path = '/me';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['externalProfile'] !== 'undefined') {
      queryParameters['externalProfile'] = parameters['externalProfile'];
    }

    queryParameters['externalProfile'] = this.transformParameter(
      queryParameters['externalProfile'],
    );

    queryParameters['withGroups'] = false;

    if (typeof parameters['withGroups'] !== 'undefined') {
      queryParameters['withGroups'] = parameters['withGroups'];
    }

    queryParameters['withGroups'] = this.transformParameter(
      queryParameters['withGroups'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} parameters.appId - AppIds to filter
   * @param {array} parameters.appItemId - ItemIds to filter. Makes sence to filter together with appId
   * @param {array} parameters.appFavType - Fav types to filter. Makes sence to filter together with appId
   * @param {array} parameters.appSpace - Filter favs by appSpace
   */
  meFavorites(parameters = {}) {
    let path = '/me/favorites';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['appId'] !== 'undefined') {
      queryParameters['appId'] = parameters['appId'];
    }

    queryParameters['appId'] = this.transformParameter(
      queryParameters['appId'],
    );

    if (typeof parameters['appItemId'] !== 'undefined') {
      queryParameters['appItemId'] = parameters['appItemId'];
    }

    queryParameters['appItemId'] = this.transformParameter(
      queryParameters['appItemId'],
    );

    if (typeof parameters['appFavType'] !== 'undefined') {
      queryParameters['appFavType'] = parameters['appFavType'];
    }

    queryParameters['appFavType'] = this.transformParameter(
      queryParameters['appFavType'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  meFavoritesCreateItem(parameters = {}) {
    let path = '/me/favorites';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Reset (remove) all favorite items created by appId / appSpace - based on token.
   * @method
   * @param {object} parameters - method options and parameters
   */
  meFavoritesResetForApp(parameters = {}) {
    let path = '/me/favorites';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * System checks the appId of the item, unless token belongs to privileged app (my.sportnet.online for example).
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} favId - Fav ID to remove
   */
  meFavoritesRemoveItem(favId, parameters = {}) {
    let path = '/me/favorites/{favId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{favId}', favId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   */
  meInvitations(parameters = {}) {
    let path = '/me/invitations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meRelations(parameters = {}) {
    let path = '/me/relations';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create new relation invitation
   */
  meNewRelationInvitation(parameters = {}) {
    let path = '/me/relations-invitation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} relatedId -
   */
  meRelationTakeover(relatedId, parameters = {}) {
    let path = '/me/relations/{relatedId}/takeover';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{relatedId}', relatedId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.offset - Offset for paginated results
   * @param {integer} parameters.limit - Limit for paginated results
   * @param {string} parameters.q -
   * @param {boolean} parameters.read -
   * @param {boolean} parameters.important -
   */
  meMessages(parameters = {}) {
    let path = '/me/messages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['offset'] = 0;

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['read'] !== 'undefined') {
      queryParameters['read'] = parameters['read'];
    }

    queryParameters['read'] = this.transformParameter(queryParameters['read']);

    if (typeof parameters['important'] !== 'undefined') {
      queryParameters['important'] = parameters['important'];
    }

    queryParameters['important'] = this.transformParameter(
      queryParameters['important'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meMarkAllMessagesAsRead(parameters = {}) {
    let path = '/me/messages';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meUnreadMessagesCount(parameters = {}) {
    let path = '/me/messages-unread-count';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} courierMessageIds -
   */
  meMessageById(courierMessageIds, parameters = {}) {
    let path = '/me/messages/{courierMessageIds}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{courierMessageIds}', courierMessageIds);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {array} courierMessageIds -
   */
  meMessageStatusChange(courierMessageIds, parameters = {}) {
    let path = '/me/messages/{courierMessageIds}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{courierMessageIds}', courierMessageIds);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meUserGroups(parameters = {}) {
    let path = '/me/groups';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meRequests(parameters = {}) {
    let path = '/me/requests';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meInvoices(parameters = {}) {
    let path = '/me/invoices';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre ziskanie download ID pdf faktury
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invoiceId -
   */
  meInvoicePdf(invoiceId, parameters = {}) {
    let path = '/me/invoices/{invoiceId}/pdf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invoiceId}', invoiceId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meAwards(parameters = {}) {
    let path = '/me/awards';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAwardsGet(itemId, parameters = {}) {
    let path = '/me/awards/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meMembership(parameters = {}) {
    let path = '/me/membership';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meMembershipGet(itemId, parameters = {}) {
    let path = '/me/membership/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meRegnrs(parameters = {}) {
    let path = '/me/regnrs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meBios(parameters = {}) {
    let path = '/me/bios';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfiles(parameters = {}) {
    let path = '/me/profiles';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesIssf(parameters = {}) {
    let path = '/me/profiles/issf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.code - Code from ISSF OAUTH response
   * @param {string} parameters.redirectUri - Redirect URI used in ISSF request
   */
  meProfilesIssfConnect(parameters = {}) {
    let path = '/me/profiles/issf/connect';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['code'] === 'undefined') {
      throw Error('Missing required parameter: code');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw Error('Missing required parameter: redirectUri');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesIssfToken(parameters = {}) {
    let path = '/me/profiles/issf/token';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  meProfilesFacebookConnect(parameters = {}) {
    let path = '/me/profiles/facebook/connect';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesFacebookDisconnect(parameters = {}) {
    let path = '/me/profiles/facebook';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesGoogleDisconnect(parameters = {}) {
    let path = '/me/profiles/google';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfilesAppleDisconnect(parameters = {}) {
    let path = '/me/profiles/apple';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meProfile(parameters = {}) {
    let path = '/me/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update basic user profile data
   */
  meProfileUpdate(parameters = {}) {
    let path = '/me/profile';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {array} parameters.type - filter addresses by type
   */
  meAddresses(parameters = {}) {
    let path = '/me/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   */
  meAddressesCreate(parameters = {}) {
    let path = '/me/addresses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAddressesGet(itemId, parameters = {}) {
    let path = '/me/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Address
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAddressesUpdate(itemId, parameters = {}) {
    let path = '/me/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meAddressesDelete(itemId, parameters = {}) {
    let path = '/me/addresses/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meBankAccounts(parameters = {}) {
    let path = '/me/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Bank Account
   */
  meBankAccountsCreate(parameters = {}) {
    let path = '/me/bank-accounts';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meBankAccountsGet(itemId, parameters = {}) {
    let path = '/me/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Bank Account
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meBankAccountsUpdate(itemId, parameters = {}) {
    let path = '/me/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meBankAccountsDelete(itemId, parameters = {}) {
    let path = '/me/bank-accounts/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meCustomFields(parameters = {}) {
    let path = '/me/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create Custom Field
   */
  meCustomFieldsCreate(parameters = {}) {
    let path = '/me/custom';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meCustomFieldsGet(itemId, parameters = {}) {
    let path = '/me/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meCustomFieldsUpdate(itemId, parameters = {}) {
    let path = '/me/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meCustomFieldsDelete(itemId, parameters = {}) {
    let path = '/me/custom/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Store various app data for user. Data is scoped for user, appId and appSpace. appId and appSpace is read from access token.
Data is in schema of type object (key-value, where value can be any type).

     * @method
     * @param {object} parameters - method options and parameters
     */
  meAppDataGet(parameters = {}) {
    let path = '/me/appdata';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   */
  meAppDataReplace(parameters = {}) {
    let path = '/me/appdata';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Update Custom Field
   */
  meAppDataUpdate(parameters = {}) {
    let path = '/me/appdata';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PATCH',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meSportOrgs(parameters = {}) {
    let path = '/me/sport-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meSportOrgItem(itemId, parameters = {}) {
    let path = '/me/sport-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meSportExpertOrgs(parameters = {}) {
    let path = '/me/sport-expert-orgs';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} itemId - Id polozky (_id v ramci subdokumentu)
   */
  meSportExpertOrgItem(itemId, parameters = {}) {
    let path = '/me/sport-expert-orgs/{itemId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{itemId}', itemId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  meLicenses(parameters = {}) {
    let path = '/me/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Create User License
   */
  meLicensesCreate(parameters = {}) {
    let path = '/me/licenses';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * List of all user roles, in all appspaces, divided by app_id

     * @method
     * @param {object} parameters - method options and parameters
       * @param {boolean} parameters.expandApp - Doplni objekty aplikacii
     */
  meAppSpaces(parameters = {}) {
    let path = '/me/appspaces';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters['expandApp'] = false;

    if (typeof parameters['expandApp'] !== 'undefined') {
      queryParameters['expandApp'] = parameters['expandApp'];
    }

    queryParameters['expandApp'] = this.transformParameter(
      queryParameters['expandApp'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * User role in appspace
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   */
  meAppSpacesForApp(appId, parameters = {}) {
    let path = '/me/appspaces/{appId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * User role in ppo
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appId - App ID
   * @param {string} appSpace - App space (PPO _id)
   */
  meAppInfoForAppSpace(appId, appSpace, parameters = {}) {
    let path = '/me/appspaces/{appId}/appspace/{appSpace}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * List of user's access tokens
   * @method
   * @param {object} parameters - method options and parameters
   */
  meAccessTokens(parameters = {}) {
    let path = '/me/access-tokens';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Change user password
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  mePasswd(parameters = {}) {
    let path = '/me/passwd';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {file} parameters.upload - File to be uploaded
   */
  mePhotoUpload(parameters = {}) {
    let path = '/me/photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'multipart/form-data');

    if (typeof parameters['upload'] !== 'undefined') {
      form['upload'] = parameters['upload'];
    }

    if (typeof parameters['upload'] === 'undefined') {
      throw Error('Missing required parameter: upload');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Metoda pre vymazanie fotky uzivatela
   * @method
   * @param {object} parameters - method options and parameters
   */
  mePhotoDelete(parameters = {}) {
    let path = '/me/photo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  meDelete(parameters = {}) {
    let path = '/me/delete';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get codelist ids for this PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   */
  listPPOCodelists(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/codelist';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get codelist for this PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} codelist - codelist id
   */
  getPPOCodelist(appSpace, codelist, parameters = {}) {
    let path = '/crm/{appSpace}/codelist/{codelist}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{codelist}', codelist);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Set codelist for this PPO
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Object containing new codelist items
   * @param {string} appSpace - App space (PPO _id)
   * @param {string} codelist - codelist id
   */
  setPPOCodelist(appSpace, codelist, parameters = {}) {
    let path = '/crm/{appSpace}/codelist/{codelist}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{codelist}', codelist);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Object containing email
   * @param {string} appSpace - App space (PPO _id)
   */
  crmSendNewUserRequestInvitation(appSpace, parameters = {}) {
    let path = '/crm/{appSpace}/user-request-invitation';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Endpoint pre vytvorenie objektov pre prihlaseneho usera z vyplnenej pozvanky
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} appSpace - App space (PPO _id)
   */
  createNewPersonRequests(appSpace, parameters = {}) {
    let path = '/create-request/{appSpace}/person';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data - Data to update
   * @param {string} appSpace - App space (PPO _id)
   */
  createPoRegistryRequest(appSpace, parameters = {}) {
    let path = '/create-request/{appSpace}/organization';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Endpoint pre ziskanie zoznamu ziadosti, ktore su zlucene do jednej requests group
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} requestsGroupId - Requests Group Id
   */
  getRequestsGroup(requestsGroupId, parameters = {}) {
    let path = '/requests-groups/{requestsGroupId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{requestsGroupId}', requestsGroupId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Obnova hesla pouzivatela
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthRestorePasswordRequest(parameters = {}) {
    let path = '/oauth/restore-password';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} passwordToken - Password token
   */
  oauthRestorePasswordInfo(passwordToken, parameters = {}) {
    let path = '/oauth/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{passwordToken}', passwordToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Obnova hesla pouzivatela
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} passwordToken - Password token
   */
  oauthRestorePassword(passwordToken, parameters = {}) {
    let path = '/oauth/restore-password/{passwordToken}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    path = path.replace('{passwordToken}', passwordToken);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Prihlasenie pouzivatela - ziskanie tokenu

Tato metoda bud vyhlasi neznamu chybu, alebo redirectne spat na referrera, alebo redirectne na redirect uri s tokenom alebo inou chybou.

V pripade redirectu na referrera moze nastat nasledovne chyby:

- INVALID_REQUEST (XHR nie je povolene)
- INVALID_CLIENT (client_id je nezname)
- INVALID_REFERER (poziadavka bola odoslana zo blbej URL)
- INVALID_CREDENTIALS (login a heslo nie su vporiadku)
- INVALID_REDIRECT_URI (redirect uri nie je medzi zadanymi v zozname aplikacii)
- INVALID_RESPONSE_TYPE (napriklad implicit metoda nemusi byt povolena vsetkym apkam)
- TOKEN_CLIENT_ID_NOT_ALLOWED (bol poskytnuty accessToken, ale nepatri my.sportnet.online)

Referer je default https://my.sportnet.online/auth/authorize pokial nie je uvedeny iny referrer ktory sa zhoduje s tymi nastavenymi v aplikacii.

Zaroven je vrateny status 302 a nastavena location hlavicka.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {string} parameters.clientId - Oauth client id
       * @param {string} parameters.redirectUri - Oauth redirect uri
       * @param {string} parameters.responseType - Response type
       * @param {string} parameters.scope - Oauth required scopes
       * @param {string} parameters.state - Oauth required scopes
       * @param {string} parameters.appSpace - Appspace Id for association user with appspace
       * @param {string} parameters.username - Users username
       * @param {string} parameters.password - 
       * @param {string} parameters.accessToken - My.sportnet.online access token
     */
  oauthAuthorize(parameters = {}) {
    let path = '/oauth/authorize';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'text/html');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    if (typeof parameters['clientId'] !== 'undefined') {
      queryParameters['client_id'] = parameters['clientId'];
    }

    queryParameters['client_id'] = this.transformParameter(
      queryParameters['client_id'],
    );

    if (typeof parameters['clientId'] === 'undefined') {
      throw Error('Missing required parameter: clientId');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw Error('Missing required parameter: redirectUri');
    }

    queryParameters['response_type'] = 'token';

    if (typeof parameters['responseType'] !== 'undefined') {
      queryParameters['response_type'] = parameters['responseType'];
    }

    queryParameters['response_type'] = this.transformParameter(
      queryParameters['response_type'],
    );

    if (typeof parameters['scope'] !== 'undefined') {
      queryParameters['scope'] = parameters['scope'];
    }

    queryParameters['scope'] = this.transformParameter(
      queryParameters['scope'],
    );

    if (typeof parameters['scope'] === 'undefined') {
      throw Error('Missing required parameter: scope');
    }

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['username'] !== 'undefined') {
      form['username'] = parameters['username'];
    }

    if (typeof parameters['password'] !== 'undefined') {
      form['password'] = parameters['password'];
    }

    if (typeof parameters['accessToken'] !== 'undefined') {
      form['accessToken'] = parameters['accessToken'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Rovnake kontroly a chyby ako v pripade autorizacie menom a heslom

## authService=issf

- Presmerovanie na ISSF s redirect URI naspat na API, obsahuje aj parametre potrebne pre finalny redirect uri
- Ziskanie tokenu pomocou ISSF code, ak chyba, presmerovanie na redirect uri s chybou
- Ziskanie usera, porovnanie s DB, update/vytvorenie konta
- Vytvorenie sportnet access tokenu pre toto issf konto
- Redirect na finalne redirect URI

     * @method
     * @param {object} parameters - method options and parameters
       * @param {string} parameters.clientId - Oauth client id
       * @param {string} parameters.redirectUri - Oauth redirect uri
       * @param {string} parameters.responseType - Response type
       * @param {string} parameters.scope - Oauth required scopes
       * @param {string} parameters.state - Oauth required scopes
       * @param {string} parameters.appSpace - Appspace Id for association user with appspace
       * @param {string} parameters.code - Code response from ISSF
       * @param {string} parameters.error - Error response from ISSF
       * @param {string} parameters.errorDescription - Error description response from ISSF
     */
  oauthAuthorizeWithIssf(parameters = {}) {
    let path = '/oauth/authorize/issf';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'text/html');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['clientId'] !== 'undefined') {
      queryParameters['client_id'] = parameters['clientId'];
    }

    queryParameters['client_id'] = this.transformParameter(
      queryParameters['client_id'],
    );

    if (typeof parameters['clientId'] === 'undefined') {
      throw Error('Missing required parameter: clientId');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw Error('Missing required parameter: redirectUri');
    }

    queryParameters['response_type'] = 'token';

    if (typeof parameters['responseType'] !== 'undefined') {
      queryParameters['response_type'] = parameters['responseType'];
    }

    queryParameters['response_type'] = this.transformParameter(
      queryParameters['response_type'],
    );

    if (typeof parameters['scope'] !== 'undefined') {
      queryParameters['scope'] = parameters['scope'];
    }

    queryParameters['scope'] = this.transformParameter(
      queryParameters['scope'],
    );

    if (typeof parameters['scope'] === 'undefined') {
      throw Error('Missing required parameter: scope');
    }

    if (typeof parameters['state'] !== 'undefined') {
      queryParameters['state'] = parameters['state'];
    }

    queryParameters['state'] = this.transformParameter(
      queryParameters['state'],
    );

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['code'] !== 'undefined') {
      queryParameters['code'] = parameters['code'];
    }

    queryParameters['code'] = this.transformParameter(queryParameters['code']);

    if (typeof parameters['error'] !== 'undefined') {
      queryParameters['error'] = parameters['error'];
    }

    queryParameters['error'] = this.transformParameter(
      queryParameters['error'],
    );

    if (typeof parameters['errorDescription'] !== 'undefined') {
      queryParameters['error_description'] = parameters['errorDescription'];
    }

    queryParameters['error_description'] = this.transformParameter(
      queryParameters['error_description'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthRevokeToken(parameters = {}) {
    let path = '/oauth/revoke';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthTokenCode(parameters = {}) {
    let path = '/oauth/token/code';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   */
  oauthTokenClientCredentials(parameters = {}) {
    let path = '/oauth/token/client-credentials';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   * @param {string} service - ID servisu odkial sa overuje token
   * @param {string} parameters.clientId - Client Id of application
   */
  oauthTokenSocialMedia(service, parameters = {}) {
    let path = '/oauth/token/social/{service}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{service}', service);

    if (typeof parameters['clientId'] !== 'undefined') {
      queryParameters['client_id'] = parameters['clientId'];
    }

    queryParameters['client_id'] = this.transformParameter(
      queryParameters['client_id'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Endpoint vymiena externy access token za sportnet access token (implicit flow), alebo code access token (code flow).
Ak je response_type=code, tak sa token ziskava klasicky cez /oauth/token/code.

Aplikacia zaroven musi mat povolenie na externu autorizaciu a sluzbu, pricom aplikacia sa zistuje podla basic auth v hlavicke (client_id+client_secret).

- Dokumentacia Apple:
  - https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_rest_api/verifying_a_user
  - https://sarunw.com/posts/sign-in-with-apple-3/
  - https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
    - zasielanie mena a priezviska uzivatela (kapitola: Handle the Response)
- Facebook: Klasika - overenie usera podla tokenu (graph api /me napriklad)
- Google:
  - https://developers.google.com/identity/sign-in/android/backend-auth#using-a-google-api-client-library

Request matchne na existujuceho pouzivatela, alebo vytvori noveho pouzivatela podla dat z tej-ktorej sluzby.

POZOR: Treba doplnit aj requesty pre my.sportnet.online a moznosti prepojenia konta na tieto sluzby.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.data - 
       * @param {string} service - ID servisu odkial sa overuje token
     */
  oauthTokenExternal(service, parameters = {}) {
    let path = '/oauth/token/external/{service}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    path = path.replace('{service}', service);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Získanie informácie o tokene.

V tele requestu môže byť zadané `app_id` a `app_space`.
Ak sú zadané, client_id v tokene sa kontroluje aj voči povoleným nadradeným aplikáciam autentifikovaného clienta.

Príklad: Máme token vygenerovaný aplikáciou `CSM`. V tele requestu okrem access_token zadám aj `app_id=CSM`.
Access token je platný ak je request autentifikovaný klientom `CSM`, alebo ak je autentifikovaný klientom,
ktorý mý nastavené ako nadradenú aplikáciu `CSM` (teda napríklad `mediamanager`).

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.data - 
     */
  oauthTokenInfo(parameters = {}) {
    let path = '/oauth/tokeninfo';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Registracia noveho usera
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.data -
   */
  oauthNewRegistration(parameters = {}) {
    let path = '/oauth/registration';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
     * Spracuje dáta poslané z kontaktného formulára z public časti stránky sportnet.online.

     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.data - 
     */
  webContactFormRequest(parameters = {}) {
    let path = '/request/web-contact-form';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    if (typeof parameters['data'] !== 'undefined') {
      body = parameters['data'];
    }

    if (typeof parameters['data'] === 'undefined') {
      throw Error('Missing required parameter: data');
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} downloadId -
   */
  getDownloaderFile(downloadId, parameters = {}) {
    let path = '/downloader/{download_id}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'application/pdf');
    headers.append('Accept', 'image/jpeg');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/gif');
    headers.append('Accept', 'text/plain');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{download_id}', downloadId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Get Public User Profile
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  getPublicUserProfile(userId, parameters = {}) {
    let path = '/users/{userId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Can be used as image src
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   */
  getPublicUserImage(userId, parameters = {}) {
    let path = '/user-photo/{userId}.jpg';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/jpeg');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   * Can be used as image src
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} userId - User Id
   * @param {string} token - Photo token
   */
  getUserImage(userId, token, parameters = {}) {
    let path = '/users/{userId}/photo/{token}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Accept', 'image/png');
    headers.append('Accept', 'image/jpeg');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{userId}', userId);

    path = path.replace('{token}', token);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }

  /**
   *
   * @method
   * @param {object} parameters - method options and parameters
   * @param {string} invitationId - Invitation Id
   */
  processInvitationResponseToGroups(invitationId, parameters = {}) {
    let path = '/crm-group-invitation/{invitationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{invitationId}', invitationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new Api();
export { Api };
