import FormField from '@sportnet/ui/FormField';
import FormFieldRedux from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import Segment from '@sportnet/ui/Segment';
import SegmentHeader from '@sportnet/ui/Segment/Header';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import Api from '../../../../Api';
import ReadOnlyTextArea from '../../../../components/ReadOnlyTextArea';
import { getProp, __ } from '../../../../utilities';
import { useAuth } from '@sportnet/auth-react';
import AppContext from '../../../../context/AppContext';
import getApiError from '../../../../utilities/getApiError';

export const FORM_NAME = 'COURIER_MESSAGE_EDIT_FORM';

const EditForm = ({ readOnly, perex, handleSubmit }) => {
  const { ppo: appSpace } = useAuth();
  const { activeAppSpace } = React.useContext(AppContext);

  const [availableSmarttagRecipients, setAvailableSmarttagRecipients] =
    React.useState();

  React.useEffect(() => {
    (async () => {
      if (!appSpace) {
        return;
      }
      const availableSmarttagRecipients = [
        {
          key: 'SportNet ID',
          values: [],
        },
      ];
      try {
        const [sportExpertTypes, appsResponse, groupsResponse, crmApp] =
          await Promise.all([
            Api.getCodelist('sport-expert-competence-type'),
            Api.crmApps(appSpace),
            Api.getPublicAppSpaceGroups(appSpace),
            Api.getPublicApp('CRM'),
          ]);

        availableSmarttagRecipients.push({
          key: 'group',
          title: 'Skupina',
          values: groupsResponse.groups.map(group => ({
            key: group._id,
          })),
        });

        availableSmarttagRecipients.push({
          key: 'sportAthlets',
          title: 'Športovci',
          values: [
            { key: 'activeOnly', title: 'Len aktívni' },
            { key: 'all', title: 'Aktívni aj neaktívni' },
          ],
        });

        availableSmarttagRecipients.push({
          key: 'sportExperts',
          title: 'Športoví odborníci',
          values: [
            { key: `activeOnly`, title: `Všetky profesie (len aktívni)` },
            { key: `all`, title: `Všetky profesie (aktívni aj neaktívni)` },
            ...sportExpertTypes.codelist
              .map(({ label, value }) => {
                return [
                  {
                    key: `activeOnly-${value}`,
                    title: `${label} (len aktívni)`,
                  },
                  {
                    key: `all-${value}`,
                    title: `${label} (aktívni aj neaktívni)`,
                  },
                ];
              })
              .reduce((r, i) => [...r, ...i]),
          ],
        });

        availableSmarttagRecipients.push({
          key: 'relatedPPOs',
          title: 'Organizácie',
          values: [
            { key: `active`, title: `Len aktívne, všetky role` },
            { key: `inactive`, title: `Len NEaktívne, všetky role` },
            ...(crmApp.roles || [])
              .map(({ name, role }) => {
                return [
                  { key: `active-${role}`, title: `Len aktívne, rola ${name}` },
                  {
                    key: `inactive-${role}`,
                    title: `Len NEaktívne, rola ${name}`,
                  },
                ];
              })
              .reduce((r, i) => [...r, ...i]),
          ],
        });

        appsResponse.apps.forEach(app => {
          availableSmarttagRecipients.push({
            key: `app-${app.app_id}`,
            title: `Používatelia aplikácie ${app.app_id}`,
            values: [
              {
                key: 'Všetky role',
              },
              ...getProp(app, ['roles'], []).map(role => ({
                key: role.role,
              })),
            ],
          });
        });
        setAvailableSmarttagRecipients(availableSmarttagRecipients);
      } catch (e) {
        const err = getApiError(e);
        alert(
          `Error: ${err.details.statusCode} ${err.details.name} ${err.message}`,
        );
      }
    })();
  }, [appSpace]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Segment
          raised
          header={
            <SegmentHeader size="xs">
              {__('Odosielateľ a príjemcovia')}
            </SegmentHeader>
          }
        >
          <FormField
            label={__('Odosielateľ')}
            readOnly
            value={`${getProp(activeAppSpace, [
              'organization_name',
            ])}, ${getProp(activeAppSpace, ['name'])}`}
          />

          <Field
            label={__('Príjemcovia')}
            type="smarttag"
            name="recipients"
            data-testid="CRM_Curier_Input_Targer"
            availableSmarttags={availableSmarttagRecipients}
            required
            readOnly={readOnly}
            component={FormFieldRedux}
          />
        </Segment>
        <Segment
          raised
          header={<SegmentHeader size="xs">{__('Obsah správy')}</SegmentHeader>}
        >
          <Field
            label={__('Predmet')}
            name="subject"
            required
            readOnly={readOnly}
            component={FormFieldRedux}
            data-testid="CRM_Curier_Input_Subject"
          />

          {readOnly ? (
            <FormGroup>
              <Label>{__('Perex')}</Label>
              <ReadOnlyTextArea data-testid="CRM_Curier_Input_Parex">
                {perex}
              </ReadOnlyTextArea>
            </FormGroup>
          ) : (
            <Field
              label={__('Perex')}
              name="perex"
              type="textarea"
              readOnly={readOnly}
              component={FormFieldRedux}
              data-testid="CRM_Curier_Input_Parex"
            />
          )}
        </Segment>
      </Form>
    </>
  );
};

EditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  perex: PropTypes.string.isRequired,
};

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  return {
    initialValues: props.data || {},
    perex: selector(state, 'perex') || '',
  };
};

const connected = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(EditForm);

export default connect(mapStateToProps)(connected);
